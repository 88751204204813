import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs';
import { ShowCaseService } from 'src/app/showCase/services/showCase.service';
import {
  getShowCases,
  likeShowCase,
  setShowCases,
  updateWatchLater,
} from './showcase.actions';

@Injectable()
export class ShowcaseEffects {
  constructor(
    private showCaseService: ShowCaseService,
    private actions$: Actions,
    private store: Store
  ) {}

  $getShowCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getShowCases),
      switchMap((action) =>
        this.showCaseService.getShowCases().pipe(
          map((response) => {
            return setShowCases({ showCases: response.showCaseResponse });
          }),
          catchError((error) => {
            return [setShowCases({ showCases: [] })];
          })
        )
      )
    )
  );

  $likeShowCase = createEffect(() =>
    this.actions$.pipe(
      ofType(likeShowCase),
      switchMap((action) =>
        this.showCaseService.likeShowCase(action.showCaseId).pipe(
          map(() => getShowCases({ filter: {} })),
          catchError((error) => {
            return [getShowCases({ filter: {} })];
          })
        )
      )
    )
  );

  $watchLater = createEffect(() =>
    this.actions$.pipe(
      ofType(updateWatchLater),
      switchMap((action) =>
        this.showCaseService.updateWatchLater(action.showCaseId).pipe(
          map(() => {
            return getShowCases({ filter: {} });
          }),
          catchError((error) => {
            return [getShowCases({ filter: {} })];
          })
        )
      )
    )
  );
}
