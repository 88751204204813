import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PersonalInterface } from 'src/app/shared/interfaces/profile.interface';
import { MediaUtilityService } from 'src/app/shared/services/media-utility.service';
import { appLogout } from 'src/app/store';
import { getTokensFailed } from 'src/app/store/account/account.actions';
import {
  selectLoggedInUser,
  selectRefreshTokenStatus,
} from 'src/app/store/account/account.selectors';
import { selectCurrentUrlPath } from 'src/app/store/core/core.selector';
import { selectUserProfileImage } from 'src/app/store/profile/profile.selector';
import { selectRecruiterProfilePhoto } from 'src/app/store/recruiter/recruiter.selector';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'nav-bar',
  templateUrl: './nav-bar.component.html',
})
export class NavBarComponent implements OnInit, OnDestroy {
  toggleOptions: boolean = false;
  isMobile: boolean = false;
  loggedInUserDetails: { emailId: string; registerType: string } = {
    emailId: '',
    registerType: '',
  };
  subscriptions: any[] = [];
  navUrl: string = '';
  profileImageUrl: string = '';
  demographicData: PersonalInterface = {} as PersonalInterface;
  mediaUrl: string = environment.api.baseUrl + environment.api.getMediaFiles;
  constructor(
    private store: Store,
    private router: Router,
    private breakpointObserver: BreakpointObserver,
    private imageService: MediaUtilityService
  ) {}
  ngOnInit(): void {
    this.subscriptions[0] = this.store
      .select(selectLoggedInUser)
      .subscribe((user) => {
        this.loggedInUserDetails = user;
      });
    this.subscriptions[1] = this.store
      .select(selectCurrentUrlPath)
      .subscribe((url) => {
        this.navUrl = url;
      });
    this.subscriptions[2] = this.store
      .select(selectUserProfileImage)
      .subscribe((data) => {
        if (data.isLoggedIn) {
          this.profileImageUrl = data.profileImage;
        }
      });

    this.subscriptions[3] = this.breakpointObserver
      .observe([Breakpoints.Handset])
      .subscribe((result) => {
        this.isMobile = result.matches;
      });

    this.subscriptions[4] = this.store
      .select(selectRefreshTokenStatus)
      .subscribe((status) => {
        if (status) {
          this.store.dispatch(getTokensFailed({ status: false }));
          this.logout();
        }
      });

    this.subscriptions[6] = this.store
      .select(selectRecruiterProfilePhoto)
      .subscribe((data) => {
        if (data.isLoggedIn) {
          this.profileImageUrl = data.profileImage;
        }
      });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  logout() {
    console.log('logout from nav bar');
    this.store.dispatch(appLogout());
    this.router.navigate(['/account/login']);
  }
}
