<div class="min-wd-300 min-ht-200 dflex-column">
  <h2
    mat-dialog-title
    style="font-weight: bold; font-size: 1.2rem; padding: 20px"
  >
    {{ data.title }}
  </h2>
  <mat-dialog-content class="mat-typography">
    <div [innerHTML]="data.content"></div>
  </mat-dialog-content>
  <mat-dialog-actions
    align="end"
    class="justify-space-bt"
    style="padding: 0px 26px 26px"
  >
    <button
      class="btn btn-primary save-btn"
      [mat-dialog-close]="false"
      *ngIf="data.negativeBtnLabel"
    >
      {{ data.negativeBtnLabel }}
    </button>
    <button
      *ngIf="data.positiveBtnLabel"
      class="btn btn-primary save-btn"
      [mat-dialog-close]="true"
      cdkFocusInitial
    >
      {{ data.positiveBtnLabel }}
    </button>
  </mat-dialog-actions>
</div>
