import { createAction, props } from '@ngrx/store';
import { IShowCase } from 'src/app/showCase/interfaces/showcase.interface';

export const getShowCases = createAction(
  '[Showcase] Get Showcases',
  props<{ filter: {} }>()
);

export const setShowCases = createAction(
  '[Showcase] Set Showcases',
  props<{ showCases: IShowCase[] }>()
);

export const createShowCase = createAction(
  '[Showcase] Create Showcase',
  props<{ showCase: any }>()
);

export const editShowCase = createAction(
  '[Showcase] Edit Showcase',
  props<{ showCase: any }>()
);

export const likeShowCase = createAction(
  '[Showcase] Like Showcase',
  props<{ showCaseId: string; currentLikeStatus: boolean }>()
);

export const onLikeShowCaseSuccess = createAction(
  '[Showcase] Like Showcase Success',
  props<{ showCaseId: string; currentLikeStatus: boolean }>()
);

export const onLikeShowCaseFailure = createAction(
  '[Showcase] Like Showcase Failure',
  props<{ showCaseId: string; currentLikeStatus: boolean }>()
);

export const updateWatchLater = createAction(
  '[Showcase] Watch Later Showcase',
  props<{ showCaseId: string; watchLaterStatus: boolean }>()
);
