import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { appLogout } from '..';
import { AuthService } from '../../account/services/auth.service';
import { openSnackBar, toggleSpinner } from '../core/core.actions';
import {
  changePassword,
  changePasswordError,
  changePasswordSuccess,
  getTokens,
  setAuthToken,
  setRefreshToken,
} from './account.actions';
import { selectRefreshToken } from './account.selectors';

@Injectable()
export class AccountEffects {
  constructor(
    private authService: AuthService,
    private actions$: Actions,
    private store: Store,
    private router: Router
  ) {}

  updatePassword$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(changePassword),
      mergeMap((payload) => {
        this.store.dispatch(
          toggleSpinner({
            spinner: { message: 'Updating Password...', show: true },
          })
        );
        return this.authService.changePassword(payload.payload).pipe(
          map((response) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            if (
              response.status === 'SUCCESS' &&
              response.code === 'CineRush_00001'
            ) {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Password updated.',
                    show: true,
                  },
                })
              );
              return changePasswordSuccess({
                status: true,
                message: response.successMessage,
              });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Password update failed.',
                    show: true,
                  },
                })
              );
              return changePasswordError({
                status: true,
                message: response.errorMessage,
              });
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              toggleSpinner({ spinner: { message: '', show: false } })
            );
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Password update failed.',
                  show: true,
                },
              })
            );
            return [changePasswordError({ status: true, message: error })];
          })
        );
      })
    );
  });

  getTokens$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(getTokens),
      withLatestFrom(this.store.pipe(select(selectRefreshToken))),
      mergeMap(([payload, refreshToken]) => {
        return this.authService.getNewTokens(refreshToken).pipe(
          map((response: any) => {
            if (response.token && response.refreshToken) {
              this.store.dispatch(setAuthToken({ token: response.token }));
              return setRefreshToken({ token: response.refreshToken });
            } else {
              this.store.dispatch(
                openSnackBar({
                  snackBar: {
                    message: 'Refresh token failed.',
                    show: true,
                  },
                })
              );
              this.router.navigate(['/account/login']);
              console.log('logout from getTokens');
              return appLogout();
            }
          }),
          catchError((error) => {
            this.store.dispatch(
              openSnackBar({
                snackBar: {
                  message: 'Refresh token failed.',
                  show: true,
                },
              })
            );
            this.router.navigate(['/account/login']);
            console.log('logout from getTokens');
            return [appLogout()];
          })
        );
      })
    );
  });
}
