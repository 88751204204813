import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MessagesService {
  constructor(private http: HttpClient) {}

  getChats(accountId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getChats,
      { accountId },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  getMessages(chatId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getMessages,
      { chatId },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  sendMessage(message: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.sendMessage,
      message,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }
}
