import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AccountState } from './account.state';

export const featureKey = 'accountState';
export const selectAccountState =
  createFeatureSelector<AccountState>(featureKey);

export const selectLoggedInUser = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return {
      emailId: state.emailId,
      registerType: state.registerType,
      accountId: state.accountId,
    };
  }
);

export const selectchangePasswordError = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return {
      isChangePasswordError: state.isChangePasswordError,
      changePasswordError: state.changePasswordError,
    };
  }
);

export const selectChangePasswordSuccess = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return {
      isChangePasswordSuccess: state.isChangePasswordSuccess,
      changePasswordSuccess: state.changePasswordSuccess,
    };
  }
);

export const selectEmailVerifyError = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return {
      isAccountVerified: state.isAccountVerified,
      emailId: state.emailToVerify,
    };
  }
);

export const selectAuthToken = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state.token;
  }
);

export const selectRefreshToken = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state.refreshToken;
  }
);
export const selectRefreshTokenStatus = createSelector(
  selectAccountState,
  (state: AccountState) => {
    return state.refreshTokenFailed;
  }
);
