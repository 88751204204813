import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AppConfigService } from './app-config.service';

@Injectable()
export class AppInitService {
  constructor(private http: HttpClient, private appConfig: AppConfigService) {}

  init() {
    return new Promise<void>((resolve, reject) => {
      this.http
        .get(environment.api.baseUrl + environment.api.allUtilData, {
          headers: { 'Content-Type': 'application/json' },
        })
        .subscribe(
          (data: any) => {
            this.appConfig.appConfigData = { ...data[0].config };
            resolve();
          },
          (error) => {
            resolve();
          }
        );
    });
  }
}
