import { IChat } from 'src/app/shared/interfaces/chat.interface';
import { IMessage } from 'src/app/shared/interfaces/message.interface';

export const initialMessagesState: MessagesState = {
  chats: [],
  selectedChat: {
    chatId: '',
    senderId: '',
    receiverId: '',
    createdOn: '',
    message: '',
    timeStamp: '',
    senderName: '',
    receiverName: '',
  },
  messages: [],
  chatsError: '',
  messagesError: '',
  sendMessageSuccess: false,
  sendMessageFailure: false,
  refreshAll: false,
};

export interface MessagesState {
  chats: IChat[];
  selectedChat: IChat;
  messages: IMessage[];
  chatsError: string;
  messagesError: string;
  sendMessageSuccess: boolean;
  sendMessageFailure: boolean;
  refreshAll: boolean;
}
