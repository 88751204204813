import { createReducer, on } from '@ngrx/store';
import {
  getIndFilmMakerProfileDetailsSuccess,
  getRecruiterDetailsSuccess,
  setProfilesFilter,
  setViewJobDetailId,
  showRecruiterProfile,
  updateRecruiterDetails,
} from './recruiter.actions';
import { RecruiterState, initialState } from './recruiter.state';

const _recruiterReducer = createReducer(
  initialState,
  on(getRecruiterDetailsSuccess, (state: RecruiterState, { payload }) => {
    return {
      ...state,
      recruiterDetails: { ...payload },
    };
  }),
  on(
    getIndFilmMakerProfileDetailsSuccess,
    (state: RecruiterState, { payload }) => {
      return {
        ...state,
        indFilmmakerProfile: { ...payload },
      };
    }
  ),
  on(
    updateRecruiterDetails,
    (
      state: RecruiterState,
      { emailId, registerType, firstName, lastName, phoneNumber, accountId }
    ) => {
      return {
        ...state,
        recruiterDetails: {
          ...state.recruiterDetails,
          emailId: emailId,
          firstName: firstName,
          lastName: lastName,
          phoneNumber: phoneNumber,
          registerType: registerType,
          accountId: accountId,
        },
      };
    }
  ),
  on(
    showRecruiterProfile,
    (state: RecruiterState, { showRecruiterProfile }) => {
      return {
        ...state,
        showRecruiterProfile: showRecruiterProfile,
      };
    }
  ),
  on(setProfilesFilter, (state: RecruiterState, { filter }) => {
    return {
      ...state,
      profileFilter: { ...filter },
    };
  }),
  on(setViewJobDetailId, (state: RecruiterState, { id }) => {
    return {
      ...state,
      viewJobDetailsId: id,
    };
  })
);

export function recruiterReducer(state: any, action: any) {
  return _recruiterReducer(state, action);
}
