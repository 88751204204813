import { createAction, props } from '@ngrx/store';
import { ProfileInterface } from 'src/app/shared/interfaces/profile.interface';

export const updateProfileState = createAction(
  '[Profile] Update Profile State',
  props<{ profileDetails: any }>()
);

export const updateLoginPersonalData = createAction(
  '[Profile] Update Profile From Login',
  props<{
    emailId: string;
    registerType: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    accountId: string;
  }>()
);

export const getProfileDetails = createAction('[Profile] Get Profile Details');

export const getProfileDetailsSuccess = createAction(
  '[Profile] Get Profile Details Success',
  props<{ payload: ProfileInterface }>()
);

export const getProfileDetailsError = createAction(
  '[Profile] Get Profile Details Error',
  props<{ payload: any }>()
);

export const setProfileDetailsAPI = createAction(
  '[Profile] API - Set Profile Details',
  props<{ payload: ProfileInterface }>()
);

export const setProfileSuccess = createAction(
  '[Profile] Update Profile Success',
  props<{ profile: any }>()
);
export const setProfileError = createAction('[Profile] Update Profile Error');

export const getAllProfiles = createAction(
  '[Profile] Get All Profiles',
  props<{
    registerType: string;
    appliedList: string[];
    filter: {};
  }>()
);

export const getAllProfilesSuccess = createAction(
  '[Profile] Get All Profiles Success',
  props<{ payload: any }>()
);

export const getAllProfilesError = createAction(
  '[Profile] Get All Profiles Error',
  props<{ error: boolean; message: string }>()
);

export const uploadProfileImage = createAction(
  '[Profile] Upload Profile Image',
  props<{ data: FormData }>()
);

export const deleteProfileImage = createAction(
  '[Profile] Delete Profile Image',
  props<{ payload: any }>()
);

export const redirectToUserProfilePage = createAction(
  '[Profile] Redirect To User Profile',
  props<{ redirectToUserProfile: boolean }>()
);

export const setReadOnlyProfile = createAction(
  '[Profile] Set Read Only Profile ',
  props<{
    profileAccountId: string;
    jobId: string;
    profileData: ProfileInterface;
  }>()
);

export const resetReadOnlyProfile = createAction(
  '[Profile] Reset Read Only Profile'
);

export const getAppliedUserProfile = createAction(
  '[Profile] Get Applied User Profile Data',
  props<{ userProfileAccountId: string; jobId: string }>()
);

export const getAppliedUserProfileSuccess = createAction(
  '[Profile] Get Applied User Profile Data Success',
  props<{ status: boolean }>()
);

export const getAppliedUserProfileFailure = createAction(
  '[Profile] Get Applied User Profile Data Failure',
  props<{ status: boolean }>()
);
