import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAuthToken } from 'src/app/store/account/account.selectors';

@Injectable()
export class ApiInterceptorService implements HttpInterceptor {
  token: string = '';

  constructor(private store: Store) {
    this.store.select(selectAuthToken).subscribe((token) => {
      this.token = token;
    });
  }
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (req.url.includes('public') || req.url.includes('login')) {
      return next.handle(req);
    } else {
      const authToken = 'Bearer ' + this.token;
      const authReq = req.clone({
        headers: req.headers.set('Authorization', authToken),
      });
      return next.handle(authReq);
      // return next.handle(authReq).pipe(
      //   map((event: HttpEvent<any>) => {
      //     if (event instanceof HttpResponse) {
      //       if (event.status !== 200) {
      //         return event;
      //       }
      //       if (event.headers.get('Authorization')) {
      //         this.store.dispatch(
      //           setAuthToken({
      //             token: event.headers.get('Authorization') || '',
      //           })
      //         );
      //       }
      //     }
      //     return event;
      //   })
      // );
    }
  }
}
