import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ProfileService {
  isProfileCompleted: boolean = false;

  constructor(private http: HttpClient) {}

  setProfileDetails(profileDetails: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.updateProfileDetails,
      profileDetails
    );
  }

  getProfileDetails(payload: {
    accountId: string;
    registerType: string;
    targetedAccountId: string;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getProfileDetails,
      payload
    );
  }

  getAllProfiles(payload: {
    accountId: string;
    registerType: string;
    appliedList: string[];
    filter: {};
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getAllProfiles,
      payload
    );
  }

  uploadProfileImage(payload: FormData): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.upload,
      payload
    );
  }
}
