import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs';
import { UserService } from 'src/app/user/services/user.service';
import { toggleSpinner } from '../core/core.actions';
import { selectJobDetails } from '../jobs/jobs.selector';
import { getPostedByDetails, setPostedByDetails } from './user.actions';

@Injectable()
export class UserEffects {
  constructor(
    private actions$: Actions,
    private store: Store,
    private userService: UserService
  ) {}

  getPostedByDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getPostedByDetails),
      withLatestFrom(this.store.pipe(select(selectJobDetails))),
      mergeMap(([action, jobDetails]) => {
        this.store.dispatch(
          toggleSpinner({ spinner: { show: true, message: 'Fetching...' } })
        );
        console.log(jobDetails);
        const { accountId, registerType } =
          jobDetails?.recruiterProfile ||
          jobDetails?.independentFilmMakerProfile;
        return this.userService
          .getPostedByDetails(accountId, registerType)
          .pipe(
            map((response) => {
              console.log(response);
              if (
                response.status === 'SUCCESS' &&
                response.code === 'CineRush_00001'
              ) {
                this.store.dispatch(
                  setPostedByDetails({
                    postedByProfile:
                      jobDetails?.recruiterProfile ||
                      jobDetails?.independentFilmMakerProfile,
                  })
                );
              } else {
                this.store.dispatch(
                  setPostedByDetails({
                    postedByProfile: {},
                  })
                );
              }
              return toggleSpinner({ spinner: { show: false, message: '' } });
            }),
            catchError((error) => {
              console.log(error);
              this.store.dispatch(
                setPostedByDetails({
                  postedByProfile: {},
                })
              );
              return [toggleSpinner({ spinner: { show: false, message: '' } })];
            })
          );
      })
    )
  );
}
