import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectLoggedInUser } from 'src/app/store/account/account.selectors';
import { constants } from '../utils/constants';

@Injectable({
  providedIn: 'root',
})
export class RecruiterGuard  {
  constructor(private store: Store) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    return new Observable<boolean>((observer) => {
      this.store.select(selectLoggedInUser).subscribe((state) => {
        if (
          state.registerType === constants.roles.recruiter ||
          state.registerType === constants.roles.indFilmMaker ||
          state.registerType === constants.roles.businessPartner
        ) {
          observer.next(true);
        } else {
          observer.next(false);
        }
      });
    });
  }
}
