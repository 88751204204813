<header class="nav-bar-wrapper">
  <div class="wrapper">
    <div class="logo">
      <a class="navbar-brand"
        ><span>
          <!-- <img src="assets/images/seeneema-log.png" class="app-logo" /> -->
          <img
            class="app-logo"
            src="https://res.cloudinary.com/dogglsjhl/image/upload/v1723001560/logo_kckvza.png"
          />
          <span>seeneemaa</span></span
        ></a
      >
    </div>
    <div class="menu">
      <a class="navbar-toggle">
        <span
          class="lni lni-menu"
          (click)="toggleOptions = !toggleOptions"
        ></span>
      </a>
      <div
        class="menu-items mar-rt-20"
        id="navbarText"
        [ngClass]="{ open: toggleOptions }"
      >
        <ul class="list-unstyled">
          <li class="nav-item login-singnup-btns">
            <a
              class="nav-link"
              routerLink="/account/login"
              (click)="toggleOptions = !toggleOptions"
              >Login</a
            >
          </li>
          <li class="nav-item login-singnup-btns">
            <a
              class="nav-link"
              routerLink="/account/signup"
              (click)="toggleOptions = !toggleOptions"
              >SignUp</a
            >
          </li>
        </ul>
      </div>
    </div>
  </div>
</header>

<div class="landing-page-main-section" *ngIf="seeneemaaData">
  <div class="section-one">
    <div class="content mb-3">
      <h1>
        {{ seeneemaaData?.sections[0]?.title }}
        <p>{{ seeneemaaData?.sections[0]?.highlighted_text }}</p>
      </h1>
      <p class="sub-text">{{ seeneemaaData?.sections[0]?.description }}</p>
    </div>
    <button
      type="join"
      class="btn btn-primary join-signup-btn"
      routerLink="/account/signup"
    >
      {{ seeneemaaData?.sections[0]?.button.text }}
    </button>
    <div class="mt-3">
      <img src="assets/images/section-1.png" class="landing-page-image" />
    </div>
  </div>
  <div class="scrollable-container-active-opp mb-5">
    <div class="scroll-wrapper">
      <div class="scroll-item">
        <span class="asterisk">*</span> {{ usersCount }} Users signed up
      </div>
      <div class="scroll-item">
        <span class="asterisk">*</span> {{ jobsCreatedCount }} Opportunities
      </div>
      <div class="scroll-item">
        <span class="asterisk">*</span> {{ recruitersCount }} Active media
        houses looking for talents
      </div>
      <div class="scroll-item">
        <span class="asterisk">*</span> {{ independentFilmMakersCount }} Active
        independent film makers
      </div>
    </div>
  </div>
  <div class="section-two" style="display: flex; justify-content: center">
    <img src="assets/images/section-2.png" class="landing-page-image mb-5" />
  </div>

  <div class="section-three mb-5">
    <div class="row">
      <div class="col-lg-6 col-sm-12 pd-40">
        <img src="assets/images/section-3.png" class="landing-page-image" />
      </div>
      <div class="col-lg-6 col-sm-12 pd-40 right-content">
        <h1>{{ seeneemaaData?.sections[1]?.title }}</h1>
        <p class="sub-text">{{ seeneemaaData?.sections[1]?.description[0] }}</p>
        <ul class="list-items">
          <li class="sub-text mb-4">
            <span
              >{{ seeneemaaData?.sections[1]?.description[1].heading }}:
            </span>
            {{ seeneemaaData?.sections[1]?.description[1].description }}
          </li>
          <li class="sub-text mb-4">
            <span
              >{{ seeneemaaData?.sections[1]?.description[2].heading }}:
            </span>
            {{ seeneemaaData?.sections[1]?.description[2].description }}
          </li>
          <li class="sub-text mb-4">
            <span
              >{{ seeneemaaData?.sections[1]?.description[3].heading }}:
            </span>
            {{ seeneemaaData?.sections[1]?.description[3].description }}
          </li>
        </ul>
        <button
          type="join"
          class="btn btn-primary join-signup-btn"
          routerLink="/account/signup"
        >
          {{ seeneemaaData?.sections[1]?.button.text }}
        </button>
      </div>
    </div>
  </div>
  <div class="section-four">
    <h1>{{ seeneemaaData?.sections[2]?.title }}</h1>
    <div class="tabs-container">
      <button
        *ngFor="
          let category of seeneemaaData?.sections[2]?.categories;
          let i = index
        "
        class="tab"
        [ngClass]="{ active: activeTab === i }"
        (click)="activeTab = i"
      >
        {{ category }}
      </button>
    </div>
    <!-- Cards Section -->
    <div class="cards-container mt-5 mb-5">
      <div class="card">
        <div class="icon">❓</div>
        <h3>{{ seeneemaaData?.sections[2]?.benefits[0].title }}</h3>
        <p>{{ seeneemaaData?.sections[2]?.benefits[0].description }}</p>
      </div>
      <div class="card">
        <div class="icon">🏅</div>
        <h3>{{ seeneemaaData?.sections[2]?.benefits[1].title }}</h3>
        <p>{{ seeneemaaData?.sections[2]?.benefits[1].description }}</p>
      </div>
      <div class="card">
        <div class="icon">🔒</div>
        <h3>{{ seeneemaaData?.sections[2]?.benefits[2].title }}</h3>
        <p>{{ seeneemaaData?.sections[2]?.benefits[2].description }}</p>
      </div>
      <div class="card">
        <div class="icon">👁️</div>
        <h3>{{ seeneemaaData?.sections[2]?.benefits[3].title }}</h3>
        <p>{{ seeneemaaData?.sections[2]?.benefits[3].description }}</p>
      </div>
      <div class="card">
        <div class="icon">🌟</div>
        <h3>{{ seeneemaaData?.sections[2]?.benefits[4].title }}</h3>
        <p>{{ seeneemaaData?.sections[2]?.benefits[4].description }}</p>
      </div>
    </div>
    <div class="text-center">
      <button
        type="join"
        class="btn btn-primary join-signup-btn"
        routerLink="/account/signup"
      >
        {{ seeneemaaData?.sections[2]?.button.text }}
      </button>
    </div>
  </div>
  <div class="section-five mt-5">
    <h1>{{ seeneemaaData?.sections[3]?.title }}</h1>
    <div class="features-container mb-5">
      <!-- First row: 3 cards -->
      <div class="feature-card">
        <div class="content">
          <!-- <img src="icon1.png" class="icon" alt="icon1"/> -->
          <div class="icon">🌟</div>
          <h3>{{ seeneemaaData?.sections[3]?.features[0].title }}</h3>
        </div>
        <p>{{ seeneemaaData?.sections[3]?.features[0].description }}</p>
      </div>

      <div class="feature-card">
        <div class="content">
          <!-- <img src="icon2.png" class="icon" alt="icon2"/> -->
          <div class="icon">📋</div>
          <h3>{{ seeneemaaData?.sections[3]?.features[1].title }}</h3>
        </div>
        <p>{{ seeneemaaData?.sections[3]?.features[1].description }}</p>
      </div>

      <div class="feature-card">
        <div class="content">
          <!-- <img src="icon3.png" class="icon" alt="icon3"/> -->
          <div class="icon">🎬</div>
          <h3>{{ seeneemaaData?.sections[3]?.features[2].title }}</h3>
        </div>
        <p>{{ seeneemaaData?.sections[3]?.features[2].description }}</p>
      </div>

      <!-- Second row: 2 cards -->
      <div class="feature-card">
        <div class="content">
          <!-- <img src="icon4.png" class="icon" alt="icon4"/> -->
          <div class="icon">🔒</div>
          <h3>{{ seeneemaaData?.sections[3]?.features[3].title }}</h3>
        </div>
        <p>{{ seeneemaaData?.sections[3]?.features[3].description }}</p>
      </div>

      <div class="feature-card">
        <div class="content">
          <!-- <img src="icon5.png" class="icon" alt="icon5"/> -->
          <div class="icon">💼</div>
          <h3>{{ seeneemaaData?.sections[3]?.features[4].title }}</h3>
        </div>
        <p>{{ seeneemaaData?.sections[3]?.features[4].description }}</p>
      </div>
    </div>
    <div class="text-center">
      <button
        type="join"
        class="btn btn-primary join-signup-btn"
        routerLink="/account/signup"
      >
        {{ seeneemaaData?.sections[3]?.button.text }}
      </button>
    </div>
  </div>
  <div class="section-six mb-5">
    <div class="row">
      <div class="col-lg-6 col-sm-12 pd-40">
        <div class="left-content">
          <h1>{{ seeneemaaData?.sections[4]?.title }}</h1>
          <p class="sub-text mb-4">
            {{ seeneemaaData?.sections[4]?.description[0] }}
          </p>
          <p class="sub-text mb-4">
            {{ seeneemaaData?.sections[4]?.description[1] }}
          </p>
          <p class="sub-text mb-4">
            {{ seeneemaaData?.sections[4]?.description[2] }}
          </p>
          <button
            type="join"
            class="btn btn-primary join-signup-btn"
            routerLink="/account/signup"
          >
            {{ seeneemaaData?.sections[4]?.button.text }}
          </button>
        </div>
      </div>
      <div class="col-lg-6 col-sm-12 pd-40">
        <img src="assets/images/section-6.png" class="landing-page-image" />
      </div>
    </div>
  </div>
  <div class="section-seven">
    <div class="blue-bg text-center pd-40">
      <h1>{{ seeneemaaData?.sections[5]?.title }}</h1>
      <div class="content">
        <p class="sub-text mb-4">
          {{ seeneemaaData?.sections[5]?.description[0] }}
        </p>
        <p class="sub-text mb-4">
          {{ seeneemaaData?.sections[5]?.description[1] }}
        </p>
        <div>
          <button type="join" class="btn btn-primary signup-btn">
            {{ seeneemaaData?.sections[5]?.button.text }}
          </button>
        </div>
      </div>
    </div>
    <div>
      <img
        src="assets/images/section-7.png"
        class="landing-page-image"
        style="width: 100%"
      />
    </div>
  </div>
</div>

<footer></footer>
