import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class JobsService {
  constructor(private http: HttpClient) {}

  createJob(jobData: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.createJob,
      jobData
    );
  }

  getJobs(payload: {
    filter: {};
    accountId: string;
    registerType: string;
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortAscending: boolean;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getJobs,
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  setFavorite(payload: { jobId: any; accountId: string }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.setFavorite,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  getJobDetails(payload: {
    jobId: string;
    accountId: string;
    registerType: string;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getJobDetails,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  applyJob(request: {
    jobId: string;
    accountId: string;
    registerType: string;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.applyJob,
      request,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  setViewed(payload: { jobId: any; accountId: string }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.setViewed,
      payload,
      { headers: { 'Content-Type': 'application/json' } }
    );
  }

  accentApplicant(request: {
    jobId: string;
    accountId: string;
    userAccountId: string;
    registerType: string;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.approveApplicant,
      request,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  rejectApplicant(request: {
    jobId: string;
    accountId: string;
    userAccountId: string;
    registerType: string;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.rejectApplicant,
      request,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  deleteJob(request: { jobId: string; accountId: string }): Observable<any> {
    return this.http.get(
      environment.api.baseUrl + environment.api.deleteJob + '/' + request.jobId,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  getAllDrafts(accountId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getAllDrafts,
      { accountId },
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  createOrUpdateDraft(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.createOrUpdateDraft,
      payload,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }

  shortlistApplicant(request: {
    jobId: string;
    accountId: string;
    userAccountId: string;
    registerType: string;
    isUserProfileShortlisted: boolean;
  }): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.shortlistuserprofileforjob,
      request,
      {
        headers: { 'Content-Type': 'application/json' },
      }
    );
  }
}
