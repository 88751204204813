import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ShowCaseService {
  constructor(private http: HttpClient) {}

  submitShowCase(request: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.createOrUpdateShowCase,
      request
    );
  }

  getShowCases(): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.getShowCases,
      {}
    );
  }

  likeShowCase(showCaseId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.likeShowCase,
      { showCaseId }
    );
  }

  updateWatchLater(showCaseId: string): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.watchLater,
      { showCaseId }
    );
  }
}
