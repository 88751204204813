import { IShowCase } from 'src/app/showCase/interfaces/showcase.interface';

export interface ShowCaseState {
  showCases: IShowCase[];
  editShowCaseId: string;
}

export const initialState: ShowCaseState = {
  showCases: [],
  editShowCaseId: '',
};
