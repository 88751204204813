import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectAuthToken,
  selectLoggedInUser,
} from '../account/account.selectors';
import { JobsState } from './jobs.state';

export const featureKey = 'jobsState';
export const selectJobsState = createFeatureSelector<JobsState>(featureKey);

export const selectCreateJobSuccess = createSelector(
  selectJobsState,
  (state: JobsState) => {
    return {
      createJobSuccess: state.createJobSuccess,
      createJobSuccessId: state.createJobSuccessId,
    };
  }
);

export const selectCreateJobFailure = createSelector(
  selectJobsState,
  (state: JobsState) => state.createJobFailure
);

export const selectJobDetails = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobDetails
);

export const selectJobDetailsError = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobDetailsError
);

export const selectSelectedJobId = createSelector(
  selectJobsState,
  (state: JobsState) => state.selectedJobId
);

export const selectJobs = createSelector(
  selectJobsState,
  (state: JobsState) => {
    let jobs = [...state.jobs].sort((jobOne, jobTwo) => {
      if (state.sortValue === 'createdDtm') {
        return new Date(jobOne.createdDtm) > new Date(jobTwo.createdDtm)
          ? -1
          : 1;
      } else {
        return jobOne.views > jobTwo.views ? -1 : 1;
      }
    });

    return {
      jobs: jobs,
      totalRecords: state.totalRecords,
    };
  }
);

export const selectJobsError = createSelector(
  selectJobsState,
  (state: JobsState) => state.jobsError
);

export const selectApplyJobSuccess = createSelector(
  selectJobsState,
  (state: JobsState) => state.applyJobSuccess
);

export const selectApplyJobFailure = createSelector(
  selectJobsState,
  (state: JobsState) => state.applyJobFailure
);

export const selectEditJob = createSelector(
  selectJobsState,
  selectAuthToken,
  selectLoggedInUser,
  (state: JobsState, token: string, loggedInUserDetails) => {
    return {
      editJob: state.jobs.find((job) => job.jobId === state.editJobId),
      token: token,
      registerType: loggedInUserDetails.registerType,
    };
  }
);

export const selectFavoriteUpdated = createSelector(
  selectJobsState,
  (state: JobsState) => state.favoriteUpdated
);

export const selectAllDrafts = createSelector(
  selectJobsState,
  (state: JobsState) => state.drafts
);

export const selectLoadDraft = createSelector(
  selectJobsState,
  (state: JobsState) => {
    return {
      loadDraft: state.drafts.find(
        (draft) => draft.draftId === state.loadDraftId
      ),
    };
  }
);
