import { NgOptimizedImage, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import en from '@angular/common/locales/en';
import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgSelectModule } from '@ng-select/ng-select';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiInterceptorService } from './shared/services/api-interceptor.service';
import { AppInitService } from './shared/services/app-init.service';
import { TokenInterceptor } from './shared/services/token-interceptor.service';
import { SharedModule } from './shared/shared.module';
import { metaReducers, reducers } from './store';
import { AccountEffects } from './store/account/account.effects';
import { JobsEffects } from './store/jobs/jobs.effects';
import { MessagesEffects } from './store/messages/messages.effects';
import { ProfileEffects } from './store/profile/profile.effects';
import { RecruiterEffects } from './store/recruiter/recruiter.effects';
import { ShowcaseEffects } from './store/showcase/showcase.effects';
import { UserEffects } from './store/user/user.effects';

registerLocaleData(en);

export function initApp(appInitService: AppInitService) {
  return (): Promise<any> => {
    return appInitService.init();
  };
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    NgSelectModule,
    AppRoutingModule,
    NgOptimizedImage,
    SharedModule,
    HttpClientModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
    }),
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: !isDevMode() }),
    EffectsModule.forRoot([
      ProfileEffects,
      RecruiterEffects,
      AccountEffects,
      JobsEffects,
      MessagesEffects,
      ShowcaseEffects,
      UserEffects,
    ]),
    NgxSpinnerModule,
    BrowserAnimationsModule,
    FormsModule,
    MatSnackBarModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ApiInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    AppInitService,
    {
      provide: APP_INITIALIZER,
      useFactory: initApp,
      deps: [AppInitService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
