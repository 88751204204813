import { createReducer, on } from '@ngrx/store';
import {
  getAllProfilesError,
  getAllProfilesSuccess,
  getAppliedUserProfileFailure,
  getAppliedUserProfileSuccess,
  getProfileDetailsError,
  getProfileDetailsSuccess,
  redirectToUserProfilePage,
  resetReadOnlyProfile,
  setReadOnlyProfile,
  updateLoginPersonalData,
  updateProfileState,
} from './profile.actions';
import { ProfileState, initialState } from './profile.state';

const _profileReducer = createReducer(
  initialState,
  on(updateProfileState, (state: ProfileState, { profileDetails }) => {
    return {
      ...state,
      profile: { ...profileDetails },
    };
  }),
  on(
    updateLoginPersonalData,
    (
      state: ProfileState,
      { emailId, registerType, firstName, lastName, phoneNumber, accountId }
    ) => {
      return {
        ...state,
        profile: {
          ...state.profile,
          demographic: {
            ...state.profile.demographic,
            emailId: emailId,
            accountId: accountId,
            firstName: firstName,
            lastName: lastName,
            phoneNumber: phoneNumber,
            registerType: registerType,
          },
        },
        registerType: registerType,
      };
    }
  ),
  on(getProfileDetailsSuccess, (state: ProfileState, { payload }) => {
    return {
      ...state,
      profile: { ...payload },
    };
  }),
  on(getProfileDetailsError, (state: ProfileState, { payload }) => {
    return { ...state, getProfileDetailsError: payload };
  }),
  on(getAllProfilesSuccess, (state: ProfileState, { payload }) => {
    return {
      ...state,
      allProfiles: payload,
    };
  }),
  on(getAllProfilesError, (state: ProfileState, { error, message }) => {
    return {
      ...state,
      allProfiles: [],
      error: error,
      errorMessage: message,
    };
  }),
  on(
    redirectToUserProfilePage,
    (state: ProfileState, { redirectToUserProfile }) => {
      return {
        ...state,
        redirectToUserProfile: redirectToUserProfile,
      };
    }
  ),
  on(
    setReadOnlyProfile,
    (state: ProfileState, { profileAccountId, jobId, profileData }) => {
      return {
        ...state,
        appliedProfile: {
          profileAccountId: profileAccountId,
          jobId: jobId,
          profileData: profileData,
        },
      };
    }
  ),
  on(resetReadOnlyProfile, (state: ProfileState) => {
    return {
      ...state,
      appliedProfile: {
        profileAccountId: initialState.appliedProfile.profileAccountId,
        jobId: initialState.appliedProfile.jobId,
        profileData: initialState.appliedProfile.profileData,
      },
    };
  }),
  on(getAppliedUserProfileSuccess, (state: ProfileState, { status }) => {
    return {
      ...state,
      isAppliedProfileSuccess: status,
    };
  }),
  on(getAppliedUserProfileFailure, (state: ProfileState, { status }) => {
    return {
      ...state,
      isAppliedProfileFailure: status,
    };
  })
);

export function profileReducer(state: any, action: any) {
  return _profileReducer(state, action);
}
