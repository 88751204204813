import { IJob } from 'src/app/recruiter/interfaces/job.interface';
import { RecruiterProfileInterface } from 'src/app/recruiter/interfaces/recruiter-profile.interface';

export const initialState: RecruiterState = {
  jobs: [],
  viewReadOnlyProfileId: '',
  showRecruiterProfile: false,
  recruiterDetails: {
    accountId: '',
    registerType: '',
    firstName: '',
    lastName: '',
    emailId: '',
    phoneNumber: '',
    regCompanyName: '',
    tradeName: '',
    companyRegNumber: '',
    establishYear: '',
    registeredCountry: null,
    industryType: '',
    gstin: '',
    primaryOfcAddress: '',
    city: '',
    state: '',
    country: '',
    zipcode: '',
    ofcPhoneNumber: '',
    emailAddress: '',
    websiteUrl: '',
    aboutCompany: '',
    primaryBusinessFocus: '',
    achievements: '',
    primaryFullName: '',
    primaryJobTitle: '',
    primaryEmail: '',
    primaryPhoneNumber: '',
    primaryLinkedInProfile: '',
    secondaryFullName: '',
    secondaryJobTitle: '',
    secondaryEmail: '',
    secondaryPhoneNumber: '',
    secondaryLinkedInProfile: '',
    productinType: '',
    servicesOffered: '',
    talentNeeds: '',
    collabMethods: '',
    orgLogo: '',
    signedOrgLogoUrl: '',
    previousExperiences: [
      {
        projectTitle: '',
        projectType: '',
        releaseYear: '',
        role: '',
        description: '',
        workLinks: '',
      },
    ],
  },
  viewJobDetailsId: '',
  profileFilter: {},
  indFilmmakerProfile: {},
};

export interface RecruiterState {
  jobs: IJob[];
  recruiterDetails: RecruiterProfileInterface;
  viewReadOnlyProfileId: string;
  viewJobDetailsId: string;
  showRecruiterProfile: boolean;
  profileFilter: any;
  indFilmmakerProfile: any;
}
