<nav-bar></nav-bar>
<main class="main">
  <div class="wrapper pt-3 settings-wrapper">
    <div class="row" style="padding: 20px; border-bottom: 1px solid #bfbfbf">
      <div class="col-12">
        <div><h3>Feedback</h3></div>
      </div>
    </div>
    <div class="row" style="padding: 20px">
      <div class="col-12">
        <div class="form-group mb-3">
          <textarea
            class="form-control"
            id="feedback"
            placeholder="Enter feedback"
            rows="8"
            #feedback
            [(ngModel)]="message"
          ></textarea>
        </div>
        <div class="form-group">
          <button
            type="submit"
            class="btn btn-primary save-btn"
            [disabled]="!feedback.value"
            (click)="onSubmit()"
          >
            Submit
          </button>
        </div>

        <div class="form-text error-message" *ngIf="submitStatus">
          {{ submitStatus }}
        </div>
      </div>
    </div>
  </div>
</main>
