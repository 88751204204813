<header class="nav-bar-wrapper">
  <div class="wrapper">
    <div class="col-lg-3 logo">
      <a
        class="navbar-brand"
        [routerLink]="
          loggedInUserDetails.registerType === 'USER' ? '/user' : '/recruiter'
        "
        ><span>
          <!-- <img src="assets/images/seeneema-log.png" class="app-logo" /> -->
          <img
            class="app-logo"
            src="https://res.cloudinary.com/dogglsjhl/image/upload/v1723001560/logo_kckvza.png"
          /><span>seeneemaa </span>
        </span></a
      >
    </div>
    <div class="col-lg-6 menu">
      <a class="navbar-toggle">
        <span
          class="lni lni-menu"
          (click)="toggleOptions = !toggleOptions"
        ></span>
      </a>
      <div
        class="menu-items mar-rt-20"
        id="navbarText"
        [ngClass]="{ open: toggleOptions }"
        [style]="{ width: isMobile ? 'auto' : '100%' }"
      >
        <ul
          class="list-unstyled"
          style="display: flex"
          [style]="{ flexDirection: isMobile ? 'column' : 'row' }"
        >
          <li
            class="nav-item"
            style="display: flex"
            *ngIf="loggedInUserDetails.emailId"
          >
            <mat-icon
              class="icon"
              style="margin-right: 10px"
              fontIcon="space_dashboard"
              [ngClass]="{
                active:
                  navUrl === '/user/dashboard' ||
                  navUrl === '/user' ||
                  navUrl === '/recruiter/dashboard' ||
                  navUrl === '/recruiter'
              }"
            ></mat-icon>
            <a
              class="nav-link"
              [ngClass]="{
                active:
                  navUrl === '/user/dashboard' ||
                  navUrl === '/user' ||
                  navUrl === '/recruiter/dashboard' ||
                  navUrl === '/recruiter'
              }"
              [routerLink]="
                loggedInUserDetails.registerType === 'USER'
                  ? '/user'
                  : '/recruiter'
              "
              (click)="toggleOptions = !toggleOptions"
              >Home</a
            >
          </li>
          <li
            class="nav-item"
            style="display: flex"
            *ngIf="loggedInUserDetails.emailId"
          >
            <mat-icon
              class="icon"
              style="margin-right: 10px"
              fontIcon="rss_feed"
              [ngClass]="{
                active: navUrl === '/showcases'
              }"
            ></mat-icon>
            <a
              class="nav-link"
              [ngClass]="{
                active: navUrl === '/showcases'
              }"
              [routerLink]="'/showcases'"
              (click)="toggleOptions = !toggleOptions"
              >Show Case</a
            >
          </li>
          <li
            class="nav-item"
            style="display: flex"
            *ngIf="
              loggedInUserDetails.emailId &&
              loggedInUserDetails.registerType === 'RECRUITER'
            "
          >
            <mat-icon
              class="icon"
              style="margin-right: 10px"
              fontIcon="group"
              [ngClass]="{
                active: navUrl === '/recruiter/profiles-search'
              }"
            ></mat-icon>
            <a
              class="nav-link"
              [ngClass]="{
                active: navUrl === '/recruiter/profiles-search'
              }"
              [routerLink]="'/recruiter/profiles-search'"
              (click)="toggleOptions = !toggleOptions"
              >Talent Search</a
            >
          </li>
          <li
            class="nav-item"
            *ngIf="isMobile && loggedInUserDetails.emailId"
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="chat"
              [ngClass]="{
                active: navUrl === '/messages'
              }"
              style="margin-right: 10px"
              routerLink="/messages"
            ></mat-icon>
            <a
              class="nav-link"
              routerLink="/messages"
              [ngClass]="{
                active: navUrl === '/messages'
              }"
              (click)="toggleOptions = !toggleOptions"
              >Messages</a
            >
          </li>

          <!-- <li
            class="nav-item"
            *ngIf="
              loggedInUserDetails.emailId &&
              loggedInUserDetails.registerType === 'USER'
            "
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="work"
              style="margin-right: 10px"
              [ngClass]="{
                active: navUrl === '/user/applied-opportunities'
              }"
            ></mat-icon>

            <a
              class="nav-link"
              routerLink="/user/applied-opportunities"
              [ngClass]="{
                active: navUrl === '/user/applied-opportunities'
              }"
              (click)="toggleOptions = !toggleOptions"
              >Applied Opportunities</a
            >
          </li>
          <li
            class="nav-item"
            *ngIf="
              loggedInUserDetails.emailId &&
              loggedInUserDetails.registerType === 'USER'
            "
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="bookmark"
              style="margin-right: 10px"
              [ngClass]="{
                active: navUrl === '/user/bookmarked'
              }"
            ></mat-icon>
            <a
              class="nav-link"
              *ngIf="loggedInUserDetails.registerType === 'USER'"
              routerLink="/user/bookmarked"
              [ngClass]="{
                active: navUrl === '/user/bookmarked'
              }"
              (click)="toggleOptions = !toggleOptions"
            >
              Saved Opportunities
            </a>
          </li> -->
          <li
            class="nav-item"
            *ngIf="isMobile && loggedInUserDetails.emailId"
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="settings"
              [ngClass]="{
                active: navUrl === '/settings'
              }"
              routerLink="/settings"
              style="margin-right: 10px"
              routerLink="/settings"
            ></mat-icon>
            <a
              class="nav-link"
              [ngClass]="{
                active: navUrl === '/settings'
              }"
              routerLink="/settings"
              (click)="toggleOptions = !toggleOptions"
              >Settings</a
            >
          </li>
          <li
            class="nav-item"
            *ngIf="isMobile && loggedInUserDetails.emailId"
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="feedback"
              [ngClass]="{
                active: navUrl === '/feedback'
              }"
              routerLink="/feedback"
              style="margin-right: 10px"
            ></mat-icon>
            <a
              class="nav-link"
              [ngClass]="{
                active: navUrl === '/feedback'
              }"
              routerLink="/feedback"
              (click)="toggleOptions = !toggleOptions"
              >Feedback</a
            >
          </li>
          <li
            class="nav-item"
            *ngIf="isMobile && loggedInUserDetails.emailId"
            (click)="logout(); toggleOptions = !toggleOptions"
            style="display: flex"
          >
            <mat-icon
              class="icon"
              fontIcon="logout"
              style="margin-right: 10px"
            ></mat-icon>
            <a class="nav-link">Logout</a>
          </li>
          <li class="nav-item" *ngIf="!loggedInUserDetails.emailId">
            <a
              class="nav-link"
              routerLink="/account/login"
              [ngClass]="{
                active: navUrl === '/account/login'
              }"
              (click)="toggleOptions = !toggleOptions"
              >Login</a
            >
          </li>
          <li class="nav-item" *ngIf="!loggedInUserDetails.emailId">
            <a
              class="nav-link"
              routerLink="/account/signup"
              [ngClass]="{
                active: navUrl === '/account/signup'
              }"
              (click)="toggleOptions = !toggleOptions"
              >SignUp</a
            >
          </li>
        </ul>
      </div>
    </div>
    <div
      class="col-lg-3 menu"
      style="justify-content: flex-end"
      *ngIf="!isMobile"
    >
      <div
        *ngIf="loggedInUserDetails.emailId"
        class="menu-items mar-rt-20"
        id="navbarText"
        style="display: flex"
      >
        <ul class="list-unstyled">
          <li class="nav-item" style="display: flex">
            <mat-icon
              class="icon"
              fontIcon="chat"
              [ngClass]="{
                active: navUrl === '/messages'
              }"
              routerLink="/messages"
              (click)="toggleOptions = !toggleOptions"
            ></mat-icon>
          </li>
          <li class="nav-item" style="display: flex">
            <mat-icon
              class="icon"
              fontIcon="settings"
              [ngClass]="{
                active: navUrl === '/settings'
              }"
              routerLink="/settings"
              (click)="toggleOptions = !toggleOptions"
            ></mat-icon>
          </li>
        </ul>
      </div>
      <div
        *ngIf="loggedInUserDetails.emailId"
        class="menu-items"
        id="navbarText"
        style="display: flex; margin-left: 20px"
      >
        <div class="upload-div nav-profile" [matMenuTriggerFor]="menu">
          <img
            onerror="this.src='/assets/images/profile-avatar.png'"
            class="profile-pic nav-profile"
            [src]="profileImageUrl"
          />
          <!-- <label
            *ngIf="!profileImageUrl"
            class="label-profile-upload nav-profile profile-pic"
          ></label> -->
        </div>
        <mat-menu #menu="matMenu">
          <button
            mat-menu-item
            [routerLink]="
              loggedInUserDetails.registerType === 'USER'
                ? '/profile'
                : '/recruiter/profile'
            "
            style="font-size: 0.8rem"
            (click)="toggleOptions = !toggleOptions"
          >
            Profile
          </button>
          <button
            mat-menu-item
            routerLink="/feedback"
            style="font-size: 0.8rem"
          >
            Feedback
          </button>

          <button
            mat-menu-item
            style="font-size: 0.8rem"
            (click)="logout(); toggleOptions = !toggleOptions"
          >
            Logout
          </button>
        </mat-menu>
      </div>
    </div>
  </div>
</header>
