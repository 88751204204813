import { HttpClient } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { selectLoggedInUser } from 'src/app/store/account/account.selectors';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'feedback',
  templateUrl: './feedback.component.html',
})
export class FeedbackComponent implements OnInit, OnDestroy {
  message: string = '';
  subscriptions: Subscription[] = [];
  loggedInUser: {
    emailId: string;
    registerType: string;
  } = {
    emailId: '',
    registerType: '',
  };
  submitStatus: string = '';
  constructor(
    private store: Store,
    private router: Router,
    private http: HttpClient
  ) {}
  ngOnInit(): void {
    this.subscriptions[0] = this.store
      .select(selectLoggedInUser)
      .subscribe((data) => {
        this.loggedInUser = data;
      });
  }

  onSubmit() {
    this.subscriptions[1] = this.http
      .post(environment.api.sendFeedBack, {
        message: this.message,
      })
      .subscribe(
        (response) => {
          this.submitStatus = 'Feedback Submitted Successfully';
          this.message = '';
        },
        (error) => {
          this.submitStatus = 'Submission failed. Please again later';
          this.message = '';
        }
      );
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
