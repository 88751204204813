export const initialState: JobsState = {
  jobs: [],
  createJobSuccess: false,
  createJobFailure: false,
  jobDetails: {},
  jobDetailsError: {},
  selectedJobId: '',
  jobsError: {},
  applyJobSuccess: {},
  applyJobFailure: {},
  editJobId: '',
  favoriteUpdated: false,
  createJobSuccessId: '',
  sortValue: 'byCreatedDate',
  drafts: [],
  loadDraftId: '',
  totalRecords: 0,
};

export interface JobsState {
  jobs: any[];
  totalRecords: number;
  jobsError: Object;
  createJobSuccess: boolean;
  createJobSuccessId: string;
  createJobFailure: boolean;
  selectedJobId: string;
  jobDetails: any;
  jobDetailsError: Object;
  applyJobSuccess: Object;
  applyJobFailure: Object;
  editJobId: string;
  favoriteUpdated: boolean;
  sortValue: string;
  drafts: any[];
  loadDraftId: string;
}
