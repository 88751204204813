import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(private http: HttpClient) {}

  login(payload: any): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.login,
      payload
    );
  }

  registerUser(payload: any): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.registerUser,
      payload
    );
  }

  changePassword(payload: {
    oldpswrd: string;
    emailId: string;
    newpswrd: string;
  }): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.changePassword,
      payload
    );
  }

  forgotPassword(payload: {
    emailId: string;
    otp: string;
    newPassword: string;
  }): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.forgotPassword,
      payload
    );
  }

  verifyEmail(payload: { emailId: string; otp: string }): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.verifyEmail,
      payload
    );
  }

  sendOtp(emailId: string): Observable<any> {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.sendOtp,
      {
        emailId,
      }
    );
  }

  validateOtp(payload: { emailId: any; otp: any }) {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.validateOtp,
      payload
    );
  }

  updateProfileVisibility(payload: {
    accountId: string;
    allowProfileVisibilty: boolean;
  }) {
    return this.http.post<any>(
      environment.api.baseUrl + environment.api.updateProfileVisibilty,
      payload
    );
  }

  getNewTokens(token: string): Observable<any> {
    return this.http.get(
      environment.api.baseUrl + environment.api.getToken + '?token=' + token
    );
  }
}
