export const initialState: CoreState = {
  spinner: {
    message: '',
    show: false,
  },
  snackBar: {
    message: '',
    show: false,
  },
  currentUrl: '/',
};

export interface CoreState {
  spinner: {
    show: boolean;
    message: string;
  };
  snackBar: {
    show: boolean;
    message: string;
  };
  currentUrl: string;
}
