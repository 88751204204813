import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
@Component({
  selector: 'dialog-content-example-dialog',
  templateUrl: 'confirmation-popup.component.html',
})
export class ConfirmationPopup {
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      title: string;
      content: string;
      positiveBtnLabel: string;
      negativeBtnLabel: string;
    }
  ) {
    // console.log(data);
  }
}
