<!-- <main class="main">
  <router-outlet></router-outlet>
</main> -->

<router-outlet></router-outlet>

<ngx-spinner
  class="spin-loader"
  bdColor="rgba(0, 0, 0, 0.8)"
  size="medium"
  type="line-spin-fade"
  [fullScreen]="true"
>
  <p class="spin-loader">{{ loaderMessage }}...</p>
</ngx-spinner>
