import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoreState } from './core.state';

export const featureKey = 'coreState';
export const selectCoreState = createFeatureSelector<CoreState>(featureKey);

export const selectSpinner = createSelector(
  selectCoreState,
  (state: CoreState) => state.spinner
);
export const selectSnackBar = createSelector(
  selectCoreState,
  (state: CoreState) => state.snackBar
);
export const selectCurrentUrlPath = createSelector(
  selectCoreState,
  (state: CoreState) => state.currentUrl
);
