<div class="wrapper footer-container">
  <div class="row m-5">
    <div class="col-sm-12 col-lg-4">
      <div class="footer-title">
        SeeNeeMaa
      </div>
      <div class="wd-100 text-center font-wt-500">
        Right oppportunities, Great talent!
      </div>
    </div>
    <div class="col-sm-12 col-lg-4"></div>
    <div class="col-sm-12 col-lg-4">
      <div>Contact Us</div>
      <div>9502244593</div>
      <div>seeneemaa.info@gmail.com</div>
      <div>Hyderabad, India</div>
    </div>
  </div>
  <div class="row m-3">
    <div class="col-12 text-center">
      © 2024 by SeeNeeMaa. All rights reserved.
    </div>
  </div>
</div>
