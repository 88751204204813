import { createFeatureSelector, createSelector } from '@ngrx/store';
import { constants } from 'src/app/shared/utils/constants';
import { selectLoggedInUser } from '../account/account.selectors';
import { selectJobs } from '../jobs/jobs.selector';
import { ProfileState } from './profile.state';

export const featureKey = 'profileState';
export const selectProfileState =
  createFeatureSelector<ProfileState>(featureKey);

export const selectAllProfiles = createSelector(
  selectProfileState,
  (state: ProfileState) => state.allProfiles
);

export const selectFullName = createSelector(
  selectProfileState,
  (state: ProfileState) =>
    state.profile.demographic.firstName + state.profile.demographic.lastName
);

export const selectProfileVisibility = createSelector(
  selectProfileState,
  (state: ProfileState) => state.profile.allowProfileVisibilty
);

export const selectUserProfileImage = createSelector(
  selectProfileState,
  selectLoggedInUser,
  (state: ProfileState, loggedInUser) => {
    return {
      profileImage: state.profile.signedProfileImageUrl,
      isLoggedIn:
        loggedInUser.accountId &&
        loggedInUser.registerType === constants.roles.user
          ? true
          : false,
    };
  }
);

export const fetchedAppliedUserProfileStatus = createSelector(
  selectProfileState,
  (state: ProfileState) => {
    return {
      isSuccess: state.isAppliedProfileSuccess,
      isFailure: state.isAppliedProfileFailure,
    };
  }
);

export const selectRedirectToUserProfile = createSelector(
  selectProfileState,
  (state: ProfileState) => state.redirectToUserProfile
);

export const selectAppliedProfile = createSelector(
  selectProfileState,
  selectJobs,
  (profilestate: ProfileState, { jobs, totalRecords }) => {
    if (!profilestate?.appliedProfile?.jobId) {
      return {
        profile: profilestate.allProfiles.find(
          (profile) =>
            profile.demographic.accountId ===
            profilestate.appliedProfile.profileAccountId
        ),
        jobId: '',
      };
    }
    const appliedProfile = jobs
      .find((job) => job?.jobId === profilestate?.appliedProfile?.jobId)
      ?.userData?.find(
        (user: any) =>
          user?.demographic?.accountId ===
          profilestate?.appliedProfile?.profileAccountId
      );

    const isShortlisted = jobs
      .find((job) => job?.jobId === profilestate?.appliedProfile?.jobId)
      ?.jobShortLists?.find(
        (user: any) =>
          user?.accountId === profilestate?.appliedProfile?.profileAccountId
      )
      ? true
      : false;
    return {
      profile: profilestate.appliedProfile.profileData,
      jobId: profilestate?.appliedProfile?.jobId,
      isApproved: appliedProfile?.jobApprovedTimeStamp ? true : false,
      isRejected: appliedProfile?.rejectedTimeStamp ? true : false,
      isShortlisted: isShortlisted,
    };
  }
);
