export const constants = {
  roles: {
    user: 'USER',
    recruiter: 'RECRUITER',
    indFilmMaker: 'INDFILMMAKER',
    businessPartner: 'BUSINESSPARTNER',
  },

  filterPageTypes: {
    applied: 'applied',
    bookmarked: 'bookmarked',
  },

  rolePaths: {
    USER: [],
    RECRUITER: [],
    INDFILMMAKER: ['show-case'],
    BUSINESSPARTNER: [],
  },
};
