<div class="dflex-row justify-space-bt wd-100">
  <div class="dflex-column justify-center"
    matSnackBarLabel
  >
    {{ message }}
  </div>
  <div class="dflex-column justify-center"
    matSnackBarActions
  >
    <mat-icon
      fontIcon="close"
      matSnackBarAction
      (click)="snackBarRef.dismissWithAction()"
    ></mat-icon>
  </div>
</div>
