import { Injectable, NgZone, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { appLogout } from 'src/app/store';
import { selectLoggedInUser } from 'src/app/store/account/account.selectors';

@Injectable({
  providedIn: 'root',
})
export class SessionTimeoutService implements OnDestroy {
  private lastActivityTime: number = Date.now();
  private readonly CHECK_INTERVAL = 60000; // 1 minute
  private readonly TIMEOUT = 15 * 60 * 1000; // 15 minutes
  private intervalId: any;
  subscriptions: any[] = [];

  constructor(
    private ngZone: NgZone,
    private router: Router,
    private store: Store
  ) {
    this.subscriptions[0] = this.store
      .select(selectLoggedInUser)
      .subscribe((user) => {
        if (user?.emailId) {
          console.log('start monitoring session', this.intervalId);
          if (!this.intervalId) {
            this.startMonitoringSession();
          }
        } else {
          console.log('clearing interval');
          this.removeEventListeners();
          this.clearActivityMonitoringInterval();
        }
      });
  }
  private removeEventListeners() {
    const events = ['mousemove', 'keypress', 'click'];
    events.forEach((event) => {
      window.removeEventListener(event, () => this.updateLastActivityTime());
    });
  }

  private initEventListeners(): void {
    const events = ['mousemove', 'keypress', 'click'];
    events.forEach((event) => {
      window.addEventListener(event, () => this.updateLastActivityTime());
    });
  }

  private updateLastActivityTime(): void {
    this.lastActivityTime = Date.now();
  }

  private startMonitoring(): void {
    this.ngZone.runOutsideAngular(() => {
      this.intervalId = setInterval(() => {
        this.checkForInactivity();
      }, this.CHECK_INTERVAL);
    });
  }

  private checkForInactivity(): void {
    const currentTime = Date.now();
    const timeSinceLastActivity = currentTime - this.lastActivityTime;
    console.log(
      'time since last activity',
      timeSinceLastActivity / 60000 + 'min'
    );
    if (timeSinceLastActivity > this.TIMEOUT) {
      this.ngZone.run(() => {
        this.removeEventListeners();
        this.clearActivityMonitoringInterval();
        if (this.router.url.includes('account') || this.router.url === '/') {
          //  this.clearActivityMonitoringInterval();
          console.log('logout from session timeout service');
          this.store.dispatch(appLogout());
        } else {
          console.log('logout from session timeout service');
          this.store.dispatch(appLogout());
          this.router.navigate(['/account/login']);
        }
      });
    }
  }

  private clearActivityMonitoringInterval(): void {
    console.log('clearing interval', this.intervalId);
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
  }

  ngOnDestroy(): void {
    console.log('destroying session timeout service');
    this.clearActivityMonitoringInterval();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }

  startMonitoringSession(): void {
    console.log('start monitoring session');
    this.initEventListeners();
    this.startMonitoring();
  }
}
