import { createAction, props } from '@ngrx/store';

export const toggleSpinner = createAction(
  '[Spinner]',
  props<{ spinner: { show: boolean; message: string } }>()
);

export const openSnackBar = createAction(
  '[SnackBar]',
  props<{ snackBar: { show: boolean; message: string } }>()
);
export const updateUrlPath = createAction(
  '[Profile] Update Path Url',
  props<{ urlPath: string }>()
);
