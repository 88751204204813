import { createAction, props } from '@ngrx/store';

export const createJob = createAction(
  '[Jobs] Create Job',
  props<{ job: any; file: any }>()
);

export const createJobSuccess = createAction(
  '[Jobs] Create Job Success',
  props<{ createJobSuccess: boolean; jobId: string }>()
);

export const createJobFailure = createAction(
  '[Jobs] Create Job Failure',
  props<{ createJobError: boolean }>()
);

export const setSelectedJobId = createAction(
  '[Jobs] Set Selected Job Id',
  props<{ id: string }>()
);

export const getJobDetails = createAction(
  '[Jobs] Get Job Details',
  props<{ jobId: string }>()
);

export const getJobDetailsSuccess = createAction(
  '[Jobs] Get Job Details Success',
  props<{ job: any }>()
);

export const getJobDetailsError = createAction(
  '[Jobs] Get Job Details Failure',
  props<{ error: any }>()
);

export const getJobs = createAction(
  '[Jobs] Get Jobs',
  props<{
    filter: {};
    applied?: boolean;
    bookmarked?: boolean;
    pageNumber: number;
    pageSize: number;
    sortField: string;
    sortAscending: false;
  }>()
);

export const getJobsSuccess = createAction(
  '[Jobs] Get Jobs Success',
  props<{ jobs: any; totalRecords: number }>()
);

export const getJobsError = createAction(
  '[Jobs] Get Jobs Failure',
  props<{ error: any }>()
);

export const applyJob = createAction(
  '[Jobs] Apply Job',
  props<{ jobId: any }>()
);

export const applyJobSuccess = createAction(
  '[Jobs] Apply Job Success',
  props<{ job: any }>()
);

export const applyJobFailure = createAction(
  '[Jobs] Apply Job Failure',
  props<{ error: any }>()
);

export const setEditJobId = createAction(
  '[Jobs] Set Edit Job',
  props<{ jobId: string }>()
);

export const setFavorite = createAction(
  '[Jobs] Set Favorite Job',
  props<{ jobId: string }>()
);

export const setFavoriteUpdated = createAction(
  '[Jobs] Set Favorite Updated',
  props<{ value: boolean }>()
);

export const setViewed = createAction(
  '[Jobs] Set Job Viewed',
  props<{ jobId: string }>()
);

export const acceptApplicant = createAction(
  '[Jobs] Accept Job Applicant',
  props<{
    jobId: any;
    userAccountId: string;
  }>()
);

export const acceptApplicantSuccess = createAction(
  '[Jobs] Accept Job Applicant Success',
  props<{ job: any }>()
);

export const acceptApplicantFailure = createAction(
  '[Jobs] Accept Job Applicant Failure',
  props<{ error: any }>()
);

export const rejectApplicant = createAction(
  '[Jobs] Reject Job Applicant',
  props<{
    jobId: any;
    userAccountId: string;
  }>()
);

export const rejectApplicantSuccess = createAction(
  '[Jobs] Reject Job Applicant Success',
  props<{ job: any }>()
);

export const rejectApplicantFailure = createAction(
  '[Jobs] Reject Job Applicant Failure',
  props<{ error: any }>()
);

export const sortJobs = createAction(
  '[Jobs] Sort Jobs',
  props<{ sortValue: string }>()
);

export const deleteJob = createAction(
  '[Jobs] Delete Job',
  props<{
    jobId: string;
  }>()
);

export const getAllDrafts = createAction('[Jobs] Get All Drafts');

export const createOrUpdateDraft = createAction(
  '[Jobs] Create Or Update Draft',
  props<{ draft: any }>()
);

export const createOrUpdateDraftSuccess = createAction(
  '[Jobs] Create Or Update Draft Success',
  props<{ draft: any }>()
);

export const createOrUpdateDraftFailure = createAction(
  '[Jobs] Create Or Update Draft Failure',
  props<{ error: any }>()
);

export const getAllDraftsSuccess = createAction(
  '[Jobs] Get All Drafts Success',
  props<{ drafts: any }>()
);

export const getAllDraftsFailure = createAction(
  '[Jobs] Get All Drafts Failure',
  props<{ error: any }>()
);

export const setLoadDraftId = createAction(
  '[Jobs] Set Load Draft Id',
  props<{ draftId: string }>()
);

export const shortlistApplicant = createAction(
  '[Jobs] Shortlist Job Applicant',
  props<{
    jobId: string;
    userAccountId: string;
    isShortlisted: boolean;
  }>()
);

export const shortlistApplicantSuccess = createAction(
  '[Jobs] Shortlist Job Applicant Success',
  props<{ job: any }>()
);

export const shortlistApplicantFailure = createAction(
  '[Jobs] Shortlist Job Applicant Failure',
  props<{ error: any }>()
);
