import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class MediaUtilityService {
  constructor(private http: HttpClient) {}

  deleteUploadedImage(payload: any): Observable<any> {
    return this.http.post(
      environment.api.baseUrl + environment.api.deleteMediaFile,
      payload
    );
  }
}
